import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography, Container } from "@material-ui/core";
import { getParticularObjectDataHanndler } from "src/ApiConfig/service";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({}));

export default function PrivacyPolicy() {
  const classes = useStyles();
  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getStaticApiHandler = async () => {
    setIsLoading(true);
    try {
      const response = await getParticularObjectDataHanndler(
        "listStaticContent"
      );
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        let result = response?.data?.result;
        console.log("Result:", result);
        // Filter data with type "Privacy-Policy"
        const privacyPolicy = result.filter(
          (item) => item.type === "Privacy-Policy"
        );
        setPrivacyPolicyData(privacyPolicy);
      } else {
        setIsLoading(false);
        setPrivacyPolicyData([]);
      }
    } catch (error) {
      console.log(error);
      setPrivacyPolicyData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStaticApiHandler();
  }, []);

  return (
    <Box className={classes.mainTermsContainer} mt={2}>
      <Container>
        <Typography variant="h1">Privacy Policy</Typography>
        {/* Render privacy policy data */}
        {
          isLoading && (
            <Box>
              <Skeleton
               animation="wave"
               height={200}
               width="100%"
               style={{ marginBottom: 6 }}
             />
           </Box>
          )
        }
        {isLoading === false && privacyPolicyData.map((item, index) => (
          <Box mt={3} key={index}>
            <Typography variant="body2" mb={1} className="">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              ></div>
            </Typography>
          </Box>
        ))}
      </Container>
    </Box>
  );
}
