import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiDialogContent: {
      root: {
        // padding: "0px",
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: "#07080e",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#fff",
      },
      iconButton: {
        padding: "6px",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#fff",
      },
      dayDisabled: {
        color: "#52565c",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "36px",
        background: "#222545",
        border: "1px solid #3F467D",
      },
      input: {
        padding: "11.5px 15px",
        color: "#fff",
        fontSize: "14px",

        borderRadius: "8px",
        "&::placeholder": {
          fontSize: "12px",
          color: "rgba(255, 255, 255, 0.7)",
        },
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
      notchedOutline: {
        borderColor: "rgb(0 0 0 / 11%)",
      },
    },
    MuiAutocomplete: {
      listbox: { background: "#1c1f36", padding: "2px 0" },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      root: {
        color: "#fff",
        backgroundColor: "#1C1F36",
      },
      elevation1: {
        // padding: "20px",
        borderRadius: "5px !important",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },

    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#fff" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      // paperScrollPaper: {
      //   Width: 450,
      //   maxWidth: "100%",
      // },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "600px ",
        background: "#2D2D2D",
        borderRadius: "8px",
      },
    },
    MuiTimelineConnector: {
      root: {
        width: "2px",
        height: "108px",
        flexGrow: "1",
        backgroundColor: "transparent",
        backgroundImage: "url(./images/line.png)",
      },
    },
    MuiInputBase: {
      input: {
        // color: "rgba(255, 255, 255, 0.25)",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.25)",
        },
        "&::after": {},
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "none",
        },
      },
      outlinedSecondary: {
        border: "1px solid rgba(255, 255, 255, 0.25)",
        "&:hover": {
          color: "#ffffff",
          background:
            "linear-gradient(105.04deg, #FFCA64 -4.56%, #CA772A 93.4%)",
          border: "1px solid rgba(255, 255, 255, 0.25)",
        },
      },
      containedSecondary: {
        backgroundColor: "#FFFFFF05",
        borderRadius: "50px",
        boxShadow: "none",
        color: "#fff",
        fontSize: "14px",
        height: "44px",
        whiteSpace: "pre",
        lineHeight: " 21px",
        padding: "10px 39px",
        marginRight: "0px",
        border: "1px solid rgb(255 255 255 / 10%)",
        "&:hover": {
          color: "#ffffff",
          background: "rgba(255, 255, 255, 0.04)",
          backgroundColor: "rgba(255, 255, 255, 0.04)",
          border: "1px solid rgb(229 161 71)",
        },
      },
      containedSizeSmall: {
        fontSize: "10px",
        fontWeight: "400",
        lineHeight: "12px",
      },
      containedPrimary: {
        background: "linear-gradient(105.04deg, #FFCA64 -4.56%, #CA772A 93.4%)",
        backgroundBlendMode: "overlay",
        color: "#fff",
        borderRadius: "50px",
        whiteSpace: "pre",
        fontSize: "14px",
        lineHeight: "21px",
        padding: "14px 35px",
        fontWeight: "500",
        backgroundColor: "transparent",
        "&:hover": {
          color: "#ffffff",
          boxShadow:
            "0 1px 0 0 #CA772A, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #CA772A, -1px 0 0 0 #CA772A, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #CA772A, 1px 1px 0 0 #CA772A, -1px -1px 0 0 rgb(244 168 26)",
          backgroundColor: "transparent",
          background: "transparent",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#ffffff",
        fontWeight: 500,
        padding: "5px 19px",
      },
      containedSizeLarge: {
        height: "47px",
        padding: "8px 36px",
        fontSize: "16px",
      },
      outlinedPrimary: {
        color: "#ffffff",
        border: "solid 1px transparent",
        height: "43px",
        padding: "11px 35px",
        fontSize: "12px",
        boxShadow: "2px 1000px 1px #1b0f20 inset",
        fontWeight: "500",
        whiteSpace: "pre",
        borderRadius: "50px",
        background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
        backgroundOrigin: "border-box",
        "@media(max-width:767px)": {
          padding: "7px 40px",
        },
        "@media(max-width:599.9px)": {
          padding: "7px 25px",
        },
        "&:hover": {
          color: "#ffffff",
          border: "solid 1px transparent",
          boxShadow: "2px 1000px 1px #1f0f23 inset",
          background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
          backgroundOrigin: "border-box",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
      outlinedSizeLarge: {
        height: "48px",
        padding: "8px 36px",
        fontSize: "16px",
        borderRadius: "50px",
      },
      contained: {
        "& .Mui-disabled": {
          color: "rgb(215 181 181 / 26%)",
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
    },
    MuiMenu: {
      // paper: { top: "47px" },
      paper: {
        backgroundColor: "#1C1F36",
      },
    },
    MuiDropzoneArea: {
      root: {
        background:
          "linear-gradient(0deg, rgb(53 50 50 / 15%), rgb(58 53 53 / 15%)),linear-gradient(0deg, rgb(66 63 63 / 4%), rgb(58 54 54 / 4%))",

        border: "1px dashed rgba(255, 255, 255, 0.3)",
        borderColor: "rgba(255, 255, 255, 0.3)",
        minHeight: "228px",
        zIndex: "9",
        backgroundColor: "none",
        borderRadius: "10px",
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: "12px",
        fontSize: "12px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px) scale(1)",
      },
    },
    MuiSelect: {
      selectMenu: {
        // paddingBottom: "20px",
        // paddingTop: "20px",
      },
    },
    MuiTableHead: {
      root: {
        background: "rgba(255, 255, 255, 0.01)",
        // borderTop: "1px solid #636262",
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiTableBody: {
      root: {
        background: "rgba(255, 255, 255, 0.04)",
      },
    },
    MuiTableRow: {
      root: {
        // borderBottom: "1px solid #636262",
        // "&:hover": {
        //   backgroundColor: "#ffffff14",
        // },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
    MuiTableCell: {
      head: {
        padding: "8px 14px",
        fontWeight: "300",
        color: "rgba(255, 255, 255, 0.60)",
        whiteSpace: "pre",
      },
      body: {
        color: "#FFF",
        whiteSpace: "pre",
      },
      root: {
        borderBottom: "none",
        padding: "12px",
      },
    },

    MuiTypography: {
      subtitle1: {
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "12px",
        fontWeight: 300,
        colorSecondary: {
          color: "rgba(255, 255, 255, 0.6)",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#fff",
      dark: "#171A2C",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
