import { currencyUnit, virtualCurrency } from "src/constants";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  makeStyles,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import { handleNegativeValue, sortAddress } from "src/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopyOutlined } from "@material-ui/icons";
import { RxCross2 } from "react-icons/rx";
import { postAPIHandler } from "src/ApiConfig/service";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useDebounce from "src/component/customHook/Debounce";
import toast from "react-hot-toast";
import { Autocomplete } from "@material-ui/lab";
import QRCode from "qrcode.react";

const useStyle = makeStyles((theme) => ({
  LoosePopModalBox: {
    position: "relative",

    "& h6": {
      color: "#fff",
    },
    "& .imgBox": {
      padding: "50px 0px 30px",
      "& img": {
        width: "100px",
      },
    },
  },
  sideContent: {
    textAlign: "center",
  },
  depositeQR: {
    " & span": {
      color: "#fff",
    },
  },
  formControl: {
    "& .MuiAutocomplete-popper": {
      "& .MuiPaper-elevation1": {
        padding: "0px !important",
      },
    },
  },
}));

export default function ModalDeposit({
  closeBox,
  getAPIHandler,
  useEffect,
  axios,
  viewWalletData,
}) {
  const classes = useStyle();
  const [selectCurrency, setSelectCurrency] = useState("");
  const [amountValue, setAmountValue] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [equivalentData, setEquivalentData] = useState();
  const [minDepositAmount, setMinDepositAmount] = useState();
  const [equivalentPayData, setEquivalentPayData] = useState();
  const [isCurrencySelected, setCurrencySelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [coinListData, setCoinListData] = useState([]);
  const deb = useDebounce(amountValue, 500);
  const checkEtherCoin =
    selectCurrency === "USDTERC20" || selectCurrency === "BTC";

  const getCoinListApi = async (source) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getCoin",
        source: source,
      });
      if (response?.data?.responseCode === 200) {
        setCoinListData(response?.data?.result?.selectedCurrencies);
      } else {
        setCoinListData();
      }
    } catch (error) {
      console.log(error);
      setCoinListData();
    }
  };

  const getDepositAmoutApi = async () => {
    try {
      setErrorMsg("");
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "getDepositBalence",
        paramsData: {
          fromCoin: virtualCurrency.toLocaleLowerCase(),
          toCoin: selectCurrency.toLocaleLowerCase(),
          amount: amountValue,
        },
      });
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        setEquivalentData(response?.data?.result);
      } else {
        setEquivalentData();
        setIsLoading(false);
        setErrorMsg(response?.data?.responseMessage);
      }
    } catch (error) {
      setEquivalentData();
      setIsLoading(false);
      setErrorMsg(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };
  const getDepositMinAmoutApi = async () => {
    try {
      setIsLoading(true);
      const response = await getAPIHandler({
        endPoint: "getMinimumDeposite",
        paramsData: {
          fromCoin: selectCurrency.toLocaleLowerCase(),
          toCoin: virtualCurrency.toLocaleLowerCase(),
        },
      });
      if (response?.data?.responseCode === 200) {
        setIsLoading(false);
        setMinDepositAmount(response?.data?.result?.fiat_equivalent);
      } else {
        setMinDepositAmount();
        setIsLoading(false);
      }
    } catch (error) {
      setMinDepositAmount();
      setIsLoading(false);
      console.log(error);
    }
  };
  const sendDepositMoney = async () => {
    try {
      setErrorMsg("");
      setIsLoading(true);
      const response = await postAPIHandler({
        endPoint: "depositMoney",
        dataToSend: {
          pay_currency: selectCurrency.toLocaleLowerCase(),
          price_amount: amountValue,
        },
      });
      if (response?.data?.responseCode === 200) {
        setEquivalentPayData(response?.data?.result);
        setIsLoading(false);
        setCurrencySelected(true);
      } else {
        setIsLoading(false);
        setEquivalentPayData();
        setErrorMsg(response?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      setEquivalentPayData();
      setErrorMsg(error?.response?.data?.responseMessage);
      console.log(error);
    }
  };

  useEffect(() => {
    deb !== "" &&
      selectCurrency !== "" &&
      !checkEtherCoin &&
      amountValue >= minDepositAmount &&
      getDepositAmoutApi();
  }, [deb, selectCurrency]);

  useEffect(() => {
    selectCurrency !== "" && getDepositMinAmoutApi();
  }, [selectCurrency]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    getCoinListApi(source);
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Box className={`container`} id="container">
      <RxCross2
        className="closeIcon"
        onClick={() => {
          closeBox();
          setSelectCurrency("");
          setAmountValue("");
          setEquivalentData();
        }}
      />
      <Box className="form-container sign-in-container">
        <form action="#">
          <Box className="displayStart">
            {isCurrencySelected && (
              <ArrowBackIcon
                style={{ color: "#fff", cursor: "pointer" }}
                onClick={() => setCurrencySelected(false)}
              />
            )}
            <Typography
              variant="h6"
              style={{ fontWeight: "600", marginLeft: "10px" }}
            >
              DEPOSIT
            </Typography>
          </Box>

          {isCurrencySelected ? (
            <VerifyModal
              equivalentData={equivalentPayData}
              selectCurrency={selectCurrency}
            />
          ) : (
            <>
              <Box mb={2} mt={3}>
                <Box className="SelectorBox">
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      freeSolo
                      options={coinListData ? coinListData : []}
                      getOptionLabel={(option) => option}
                      value={selectCurrency}
                      disabled={isLoading}
                      PaperComponent="Box"
                      onChange={(event, newValue) => {
                        setSelectCurrency(
                          newValue ? newValue.toUpperCase() : ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select coin"
                        />
                      )}
                      noOptionsText="No matching coin found"
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box mb={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  placeholder={
                    checkEtherCoin
                      ? "Amount you will be paying"
                      : "Enter Amount"
                  }
                  value={amountValue}
                  disabled={selectCurrency === "" || isLoading}
                  onChange={(e) => setAmountValue(e.target.value)}
                  onKeyDown={(event) => handleNegativeValue(event)}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "*" ||
                      event?.key === "/"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="body1"
                          style={{ color: "#f4a91b" }}
                        >
                          {" "}
                          {currencyUnit}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText error>
                  {amountValue !== "" &&
                    amountValue < minDepositAmount &&
                    `Please enter greater than ${parseFloat(
                      minDepositAmount
                    ).toFixed(3)} ${currencyUnit}`}
                </FormHelperText>
                {(selectCurrency === "USDTERC20" ||
                  selectCurrency === "BTC") && (
                  <Box mt={1}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "12px",
                        lineHeight: "1.25",
                        letterSpacing: "0.000938em",
                      }}
                    >
                      The equivalent amount of {currencyUnit} that you receive
                      is reflected on Afterpay transactions.
                    </Typography>
                  </Box>
                )}
              </Box>
              {!checkEtherCoin && (
                <Box mb={1}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    disabled
                    value={equivalentData?.estimated_amount}
                    type="number"
                    placeholder="Amount you will be paying"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {selectCurrency !== "" && selectCurrency}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
              <Box mt={2}>
                <FormHelperText error>
                  {errorMsg !== "" && errorMsg}
                </FormHelperText>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    amountValue < minDepositAmount ||
                    amountValue === "" ||
                    (!checkEtherCoin && !equivalentData) ||
                    isLoading ||
                    selectCurrency === ""
                  }
                  onClick={() => sendDepositMoney()}
                >
                  {isLoading ? "Loading..." : "Pay"}
                </Button>
              </Box>
              <iframe
                className="mt-3 rounded-lg"
                height="315"
                src="https://www.youtube.com/embed/xxHa2TydxLs?si=RRiE0rFgowZ44kC3"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
              ></iframe>
            </>
          )}
        </form>
      </Box>
      <Box className="overlay-container">
        <Box className="overlay">
          <Box className={`overlay-panel overlay-right textLeft`}>
            <RxCross2
              className="closeIcon"
              onClick={() => {
                closeBox();
                setSelectCurrency("");
                setAmountValue("");
                setEquivalentData();
              }}
            />
            <Box p={2} className={classes.sideContent}>
              <img
                src="images/logo.png"
                alt="BetbyHeart"
                style={{ maxWidth: "135px", margin: "auto" }}
              />
              {/* <img
                src="/images/deposite.svg"
                style={{ width: "auto", maxWidth: "223px", marginTop: "28px" }}
              /> */}
              <Typography
                variant="body2"
                color="secondary"
                style={{ color: "#8C8FAF", marginTop : '1rem' }}
              >
                Deposit funds into your internal wallet with absolute security.
              </Typography>
              <Box mt={2}>
                <img
                  src="images/deposit.png"
                  alt="BetbyHeart"
                  style={{ maxWidth: "100%" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
const VerifyModal = ({ equivalentData, selectCurrency }) => {
  const classes = useStyle();
  const payAddress = equivalentData?.pay_address;

  return (
    <>
      <Box m={2} className="displayCenter">
        <QRCode value={payAddress} size={256} />
      </Box>
      <Box mt={1} className={`${classes.depositeQR} displayColumn`}>
        <Typography variant="body2" color="primary">
          Amount :{" "}
          <span>
            {equivalentData?.pay_amount ? equivalentData?.pay_amount : "0"}{" "}
            {selectCurrency}
            {equivalentData?.pay_amount > 0 && (
              <CopyToClipboard text={equivalentData?.pay_amount}>
                <IconButton style={{ padding: "0px" }}>
                  <FileCopyOutlined
                    style={{
                      cursor: "pointer",
                      color: "rgba(255, 202, 100, 1)",
                      fontSize: "18px",
                      marginLeft: "5px",
                    }}
                    onClick={() => toast.success("Copied")}
                  />
                </IconButton>
              </CopyToClipboard>
            )}
          </span>
        </Typography>
        <Box mt={1}>
          <Typography variant="body2" color="primary">
            Paying address :{" "}
            <span>
              {sortAddress(equivalentData?.pay_address)}
              <CopyToClipboard text={equivalentData?.pay_address}>
                <IconButton style={{ padding: "0px" }}>
                  <FileCopyOutlined
                    style={{
                      cursor: "pointer",
                      color: "rgba(255, 202, 100, 1)",
                      fontSize: "18px",
                      marginLeft: "5px",
                    }}
                    onClick={() => toast.success("Copied")}
                  />
                </IconButton>
              </CopyToClipboard>
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
