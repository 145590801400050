
import { gameSound } from "../../../../App";
// import {  inflatData } from "../socket/publishEvent";

// Function to get item from sessionStorage
export const getSessionStorageItem = (key) => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error getting item from sessionStorage for key "${key}":`, error);
      return null;
    }
  };
  
  // Function to set item in sessionStorage
  export const setSessionStorageItem = (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting item in sessionStorage for key "${key}":`, error);
    }
  };

  export const resetSessionStorage = () =>{
    setSessionStorageItem("gameSession", null);
    setSessionStorageItem('playerLeft', null);
    setSessionStorageItem('freePlayLocalWinner',null);
    setSessionStorageItem('setLocalWinnerForMultiplayer',null);
    setSessionStorageItem('winnersOrder' , []);
    setSessionStorageItem('currentPlayerFinishedToken', 0);
    setSessionStorageItem('playersWaitTime' , null);
    setSessionStorageItem('localWinners' , null);
    setSessionStorageItem('gameObj' , null);
    setSessionStorageItem('savePrevQuickPlay' , null);

     // stop bg sound
     gameSound.pauseBgAudio();

     
  }


  export const updateSession = (roomCustomPropertiesfromServer) => {
    const roomCustomProperties = roomCustomPropertiesfromServer

    console.warn("roomCustomProperties",roomCustomProperties)
   
    return new Promise((resolve,reject) => {
      try {
       
        let gameSession = null;
        if('players' in roomCustomProperties) {
          gameSession = getSessionStorageItem("gameSession");
          setSessionStorageItem("gameSession",{
            ...gameSession,
            players : roomCustomProperties?.players
          });
        } 
        if('game' in roomCustomProperties) {
          gameSession = getSessionStorageItem("gameSession");
          let newData = {
            ...gameSession,
            game : roomCustomProperties?.game
          }
          setSessionStorageItem("gameSession",newData);
        } 
        if('playerTokens' in roomCustomProperties) {
          gameSession = getSessionStorageItem("gameSession");
          let newData = {
            ...gameSession,
            playerTokens : roomCustomProperties?.playerTokens
          }
          setSessionStorageItem("gameSession",newData);
        } 
         resolve(getSessionStorageItem("gameSession"));
       } catch (error) {
         reject(error);
       }
    })
    // return getSessionStorageItem("gameSession");
  }

