import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AuthContext } from "src/context/Auth";
import React, { useRef, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Formik, Form } from "formik";
import * as yep from "yup";
import axios from "axios";
import ApiConfig from "src/ApiConfig/ApiConfig";
import ButtonLoader from "src/component/ButtonLoader";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { getAPIHandler } from "src/ApiConfig/service";
import { MdVerified } from "react-icons/md";
import FacebookLogin from "react-facebook-login";
import { FaFacebookF } from "react-icons/fa6";
import TermAndConditionMsgModal from "./TermAndConditionMsgModal";
import TermAndConditionAndPrivacyPolicyStaticModal from "./TermAndConditionAndPrivacyPolicyStaticModal";
import { baseurl } from "../../ApiConfig/ApiConfig";

const useStyle = makeStyles((theme) => ({
  optFieldBox: {
    margin: "24px 0px 16px",
  },
  errorPassClx: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  newbox: {
    color: "rgb(255, 165, 0)",
    cursor: "pointer",
  },
}));

export default function LoosePopModal(props) {
  let delayTimer;
  const classes = useStyle();
  // const clientId = "1844775209335166"; ///rajan
  const clientId = "949292796787340"; ///anil sir
  const auth = useContext(AuthContext);
  const minute = auth.timeLeft?.minutes?.toString();
  const second = auth.timeLeft?.seconds?.toString();
  const { isSignUpActive, setIsSignUpActive } = props;
  const [forgotPass, setForgotPass] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [resetPass, setresetPass] = useState(false);
  const [isloading, setLoader] = useState(false);
  const [loader, setloader] = useState(false);

  const [showPasswordLog, setShowPasswordLog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [otpVerifyForSignUp, setOtpVerifyForSignUp] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showCheckTCM, setshowCheckT] = useState(false);
  const textFieldRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);





  const [agencyCode, setAgencyCode] = useState("anfp8G")
  const [referredBy, setReferredBy] = useState("")




  const [userLocation, setUserLocation] = useState({
    city: "",
    country_code: "",
    country_name: "",
    region_name: ""
  })


  const [formState, setFormState] = useState(null);
  const [isSocialLogin, setIsSocialLogin] = useState(false);
  const [showTAndConditionPopup, setshowTAndConditionPopup] = useState({
    show: false,
    type: null
  });
  const [googleResonseLoading, setGoogleResponseLoader] = useState(false);



  console.log("showCheckTCM", showCheckTCM)


  const fetchLocation = async () => {
    // alert("hiu")
    await axios.get('https://ip-location.betbyheart.com/')
      .then(data => {
        let finaldata = data.data.location
        setUserLocation({
          ...userLocation,
          city: finaldata.city,
          country_code: finaldata.countryCode,
          country_name: finaldata.country,
          region_name: finaldata.regionName
        })
        console.log("test2", data.data.location)
      })
      .catch(err => console.log("test3", err))
  }

  useEffect(() => {
    fetchLocation()
  }, [isSignUpActive])



  const getUserAgencyCode = async () => {
    try {
      let getAgencyCode = await axios.post(`${baseurl}/api/v1/admin/getreferral`, {
        country: userLocation.country_name,
        state: userLocation.region_name
      })
      if (getAgencyCode.data.data.referral_code !== "" || getAgencyCode.data.data.referral_code) {
        // console.log(getAgencyCode)
        setAgencyCode(getAgencyCode.data.data.referral_code)
      }
    } catch (err) {
      console.log("Something Went Wrong!!")
    }
  }

  useEffect(() => {
    getUserAgencyCode()
  }, [userLocation, isSignUpActive])



  useEffect(() => {
    if (localStorage.getItem("referralCode") !== 'undefined') {
      console.log(localStorage.getItem("referralCode"))
      setReferredBy(localStorage.getItem("referralCode"))

    }
  }, [isSignUpActive])



  const openTernAndConditionModal = async (values) => {
    setLoader(true);
    setTimeout(() => {
      const data = {
        // fullName: values.fullName,
        // userName: values.userName,
        email: values.email,
        password: values.password,
        // countryCode: values.countryCode,
        // mobileNumber: values.mobileNumber.toString(),
        confirmPassword: values.confirmpassword,
        referralCode: values.inviteCode,
        // referralCountry: userLocation.country_name
      }
      setFormState(data);
      setshowCheckT(true);
      setLoader(false);
      setIsSocialLogin(false);
    }, 1200);
  }

  function generateUniqueName(playerName) {
    const timestamp = Date.now(); // Get current timestamp in milliseconds
    const uniqueName = `${playerName}_${timestamp}`;
    return uniqueName;
  }


  const handleCreateAccount = async () => {

    console.log("agencyCode", agencyCode)
    console.log("referralCode", referredBy)
    // console.log("handleCreateAccount formState>>>",formState);


    if (apiError !== "") return;

    try {
    
      setLoader(true);
      const userName = generateUniqueName("user");
      const res = await axios({
        method: "POST",
        url: ApiConfig.signup,
        data: {
          fullName: userName,
          userName: userName,
          email: formState.email,
          password: formState.password,
          // countryCode: values.countryCode,
          // mobileNumber: values.mobileNumber.toString(),
          confirmPassword: formState.confirmPassword,
          referralCode: localStorage.getItem("referralCode") !== "undefined" ? localStorage.getItem("referralCode") : agencyCode,
          referralCountry: userLocation.country_name,
          referralState: userLocation.region_name
          // referralCountry: userLocation.country_name
        },
      });
      if (res.data.responseCode === 200) {
        toast.success("OTP has been sent.");
        setLoader(false);
        setOtpVerifyForSignUp(true);
        setshowCheckT(false);
        auth.setEndtime(moment().add(3, "m").unix());
        localStorage.setItem("email", formState.email);
      } else {
        setLoader(false);
        setshowCheckT(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      setshowCheckT(false);
      if (error?.response?.data) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleSignUpOtp = async (values) => {
    try {
      setLoader(true);

      const res = await axios({
        method: "POST",
        url: ApiConfig.verifyOtp,
        data: {
          otp: values.otp.toString(),
          email: window.localStorage.getItem("email"),
        },
      });

      if (res.data.responseCode === 200) {
        toast.success("OTP has been verified.");
        setLoader(false);
        setIsSignUpActive(false);
        setOtpVerifyForSignUp(false);
      } else {
        setLoader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.data) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleSignIn = async (values) => {
    try {
      setLoader(true);

      const res = await axios({
        method: "POST",
        url: ApiConfig.login,
        data: {
          email: values.email,
          password: values.password,
        },
      });

      if (res.data.responseCode === 200) {
        if (!res?.data?.result?.otpVerification) {
          auth.setEndtime(moment().add(3, "m").unix());
          localStorage.setItem("email", values.email);
          setOtpVerifyForSignUp(true);
          setIsSignUpActive(true);
        } else {
          sessionStorage.setItem("token", res.data.result.token);
          auth.userLogIn(true, res.data.result.token);
          toast.success("log in successfully.");
          setLoader(false);
          props.closeBox();
        }
      } else {
        toast.error(res.data.responseMessage);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (error?.response?.data) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleForget = async (values) => {
    try {
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.forgotPassword,
        data: {
          email: values.email,
        },
      });
      if (res.data.responseCode === 200) {
        toast.success("OTP has been sent.");
        setLoader(false);
        setOtpVerify(true);
        localStorage.setItem("email", values.email);
        auth.setEndtime(moment().add(3, "m").unix());
      } else {
        setLoader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.data) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
  };


  const handleFormSubmitOTP = async (values) => {
    try {
      setLoader(true);

      const res = await axios({
        method: "POST",
        url: ApiConfig.verifyOtp,
        data: {
          otp: values.otp.toString(),
          email: window.localStorage.getItem("email"),
        },
      });

      if (res.data.responseCode === 200) {
        toast.success("OTP has been verified.");
        setLoader(false);
        setresetPass(true);
        localStorage.setItem("userid", res.data.result._id);
      } else {
        setLoader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.data) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleFormresetPassword = async (values) => {
    setLoader(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.resetPassword,
        data: {
          userId: window.localStorage.getItem("userid"),
          password: values.password,
          confirmPassword: values.confirmpassword,
        },
      });

      if (res.data.responseCode === 200) {
        toast.success("password reset successfully.");
        setForgotPass(false);
        setLoader(false);
        setresetPass(false);
        setOtpVerify(false);
      } else {
        setLoader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.data) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const resetotphandle = async (values) => {
    setloader(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.resendOTP,
        data: {
          email: localStorage.getItem("email"),
        },
      });
      if (res.data.responseCode === 200) {
        toast.success("OTP has been sent to your registered email address.");
        setloader(false);
        auth.setEndtime(moment().add(3, "m").unix());
      } else {
        setloader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setloader(false);

      if (error?.response?.data) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const fetchUserData = async (token) => {
    try {
      const res = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`);
      const data = await res.json();
      return data;
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }


  const responseGoogle = async (response, type) => {
    var token = response;
    // debugger;
    var decoded = type === "facebook" ? response : await fetchUserData(token);
    try {
      localStorage.setItem('socialEmail', decoded?.email.toLowerCase())
      const creadentails = {
        email: decoded?.email.toLowerCase(),
        fullName: decoded?.name,
        profilePic:
          type === "facebook" ? decoded?.picture?.data?.url : decoded?.picture,
        socialType: "Google",
        referralCountry: userLocation.country_name,
        referralState: userLocation.region_name,
        referralCode: localStorage.getItem("referralCode") !== "undefined" ? localStorage.getItem("referralCode") : agencyCode
      };
      setGoogleResponseLoader(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.socialLogin,
        data: creadentails,
      });
      if (res.data.responseCode === 200) {
        sessionStorage.setItem("token", res.data.result.token);
        toast.success("log in successfully.");
        setLoader(false);
        setGoogleResponseLoader(false);
        if (res.data.result.alreadyExist === false) {
          setIsSocialLogin(true);
          setshowCheckT(true);
          auth.userLogIn(true, res.data.result.token)
        } else {
          props.closeBox();
          auth.userLogIn(true, res.data.result.token)
        }
      } else {
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.responseMessage);
    } finally {
      setGoogleResponseLoader(false);
    }
  };

  const fetchDataFromAPI = async (userNme) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getUsername",
        paramsData: {
          userName: userNme,
        },
      });
      if (response?.status === 200) {
        return response;
      } else {
        return { error: response.data.responseMessage };
      }
    } catch (error) {
      console.log(error);
      return { error: "Username already exists" };
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // Prevent default tab behavior
      passwordRef.current.focus(); // Move focus to the password field
    }
  };

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.value = ""; // Clear the input value
    }
  }, []);




  // useEffect(() => {
  //   alert(localStorage.getItem("referralCode").length > 6 ? localStorage.getItem("referralCode").slice(0, 6) : localStorage.getItem("referralCode"))
  // }, [isSignUpActive])

  return (
    <>
      <TermAndConditionMsgModal
        open={showCheckTCM}
        setshowCheck={setshowCheckT}
        handleCreateAccount={handleCreateAccount}
        setLoader={setLoader}
        isSocialLogin={isSocialLogin}
        closeBox={props.closeBox}
      />

      <TermAndConditionAndPrivacyPolicyStaticModal
        open={showTAndConditionPopup.show}
        setHide={setshowTAndConditionPopup}
        type={showTAndConditionPopup.type}
      />

      <Box
        className={`auth-modal ${isSignUpActive ? "right-panel-active" : ""}`}
        id="container"
      >
        {" "}
        <RxCross2
          className="closeIcon"
          onClick={() => {
            props.closeBox();
          }}
        />
        <Box className="form-container sign-up-container">
          {/* {userLocation.country_name} */}
          {!otpVerifyForSignUp && (
            <Formik
              onSubmit={(values) => openTernAndConditionModal(values)}
              initialValues={{
                email: "",
                password: "",
                fullName: "",
                userName: "",
                mobileNumber: "",
                confirmpassword: "",
                inviteCode:
                  localStorage.getItem("referralCode") !== "undefined" ? localStorage.getItem("referralCode") : agencyCode,
                // check: false
              }}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={yep.object().shape({
                email: yep
                  .string()
                  .max(60, "Should not exceeds 60 characters.")
                  .email("Please enter a valid email address.")
                  .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
                  .required("Email is required."),
                // fullName: yep
                //   .string()
                //   .required("Full name is required.")
                //   .matches(
                //     /^[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*)*$/g,
                //     "Please enter a valid name."
                //   )
                //   .min(3, "Please enter atleast 3 characters.")
                //   .max(32, "You can enter only 32 characters."),
                // userName: yep
                //   .string()
                //   .trim()
                //   .min(3, "Please enter atleast 3 characters.")
                //   .max(30, "You can enter only 30 characters.")
                //   .required("Username is required.")
                //   .matches(
                //     /^[a-zA-Z0-9]+(?:[_-][a-zA-Z0-9]+)*$/,
                //     "Enter valid username."
                //   ),
                inviteCode: yep
                  .string()
                  .trim(),
                password: yep
                  .string()
                  .trim()
                  .required("Please enter a password.")
                  .min(4, "Please enter at least 4 characters."),
                confirmpassword: yep
                  .string()
                  .required("Please enter confirm password.")
                  .oneOf([yep.ref("password"), null], "Password must match."),

                // mobileNumber: yep
                //   .string()
                //   .required("Phone number is required.")
                //   .max(13, "Enter a valid phone number.")
                //   .min(7, "Must be only 7 digits.")
                //   .test(
                //     "notAllRepeatedDigits",
                //     "Phone number cannot have all repeated digits.",
                //     (value) => {
                //       const numericValue = value?.replace(/[^0-9]/g, "");
                //       return !/(\d)\1{6,}/.test(numericValue);
                //     }
                //   ),

                // check: yep
                //   .bool()
                //   .oneOf(
                //     [true],
                //     "Please accept the terms and conditions, privacy policy and user agreement of BET BY HEART."
                //   ),
              })}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                setFieldError,
              }) => (
                <Form>
                  <Box mb={2} align="center">
                    <Typography variant="h6" style={{ fontWeight: "600" }}>
                      Agency Referral Code -{localStorage.getItem("referralCode") !== "undefined" ? localStorage.getItem("referralCode") : agencyCode}
                    </Typography>
                  </Box>

                  <Box className="h-[80%] flex flex-col justify-center items-start">
                    <Box mb={2}>
                      <Typography variant="h6" style={{ fontWeight: "600" }}>
                        SIGN UP
                      </Typography>
                    </Box>

                    <Grid container spacing={1}>
                      {/* <Grid item xs={12} sm={12}>
                    {" "}
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="text"
                      placeholder="Full name"
                      value={values.fullName}
                      onChange={handleChange}
                      name="fullName"
                      onBlur={handleBlur}
                    />
                    <FormHelperText error>
                      {touched.fullName && errors.fullName}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      ref={textFieldRef}
                      variant="outlined"
                      fullWidth
                      type="text"
                      placeholder="Username"
                      value={values.userName}
                      name="userName"
                      onBlur={handleBlur}
                      autoComplete="off"
                      onChange={async (e) => {
                        handleChange(e);
                        clearTimeout(delayTimer);
                        const username = e.target.value;
                        delayTimer = setTimeout(async () => {
                          const userData =
                            username !== "" &&
                            (await fetchDataFromAPI(username));
                          if (userData && userData.error) {
                            setFieldError("userName", userData.error);
                            setApiError(userData.error);
                            setShowVerificationIcon(false);
                          } else if (userData) {
                            setApiError("");
                            handleChange({
                              target: {
                                name: "userName",
                                value: username,
                              },
                            });
                            setShowVerificationIcon(true);
                          }
                        }, 1000);
                      }}
                      InputProps={
                        showVerificationIcon &&
                          !errors.userName &&
                          touched.userName
                          ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <MdVerified
                                  style={{ fontSize: "19px", color: "green" }}
                                />
                              </InputAdornment>
                            ),
                          }
                          : {}
                      }
                    />
                    <FormHelperText error>
                      {(touched.userName && errors.userName) || apiError}
                    </FormHelperText>
                  </Grid> */}
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          name="email"
                          onBlur={handleBlur}
                        />
                        <FormHelperText error>
                          {touched.email && errors.email}
                        </FormHelperText>
                      </Grid>
                      {/* <Grid item xs={12} sm={12}>
                    {" "}
                    <Box className={classes.phoneInputClx}>
                      <PhoneInput
                        country={"in"}
                        name="mobileNumber"
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={values.mobileNumber}
                        error={Boolean(
                          touched.mobileNumber && errors.mobileNumber
                        )}
                        onBlur={handleBlur}
                        onChange={(mobileNumber, e) => {
                          setFieldValue("mobileNumber", mobileNumber);
                        }}
                        inputRef={phoneRef}
                        onKeyDown={handleKeyDown}
                      />
                    </Box>
                    <FormHelperText error>
                      {touched.mobileNumber && errors.mobileNumber}
                    </FormHelperText>
                  </Grid> */}
                      {/* <Grid item xs={12} sm={12}>
                   
                    <select
                      fullWidth
                      className="countrySelectLogin"
                      placeholder="select country"
                      id="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      error={Boolean(touched.country && errors.country)}
                    >
                      <option className="countrySelectLogin" value="" >Select Country</option>
                      {
                        countries.map((dt, index) => (
                          <option value={dt.label} key={index}>{dt.label}</option>
                        ))
                      }

                    </select>
                    <FormHelperText error>
                      {touched.country && errors.country}
                    </FormHelperText>
                  </Grid> */}
                      <Grid item xs={12} sm={12} className="hidden">
                        <TextField
                          type="text"
                          placeholder="Referral Code"
                          fullWidth
                          name="inviteCode"
                          id="inviteCode"
                          variant="outlined"
                          value={values.inviteCode.length > 6 ? values.inviteCode.slice(0, 6) : values.inviteCode}
                          error={Boolean(touched.inviteCode && errors.inviteCode)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // disabled={localStorage.getItem("referralCode") === "undefined" ? true : false}
                          disabled={true}
                        />

                        <FormHelperText error className={classes.helperText}>
                          {touched.inviteCode && errors.inviteCode}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          inputRef={passwordRef}
                          value={values.password}
                          onChange={handleChange}
                          name="password"
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  <Box className={classes.passsec}>
                                    {showPassword ? (
                                      <img
                                        src="/images/Eye.png"
                                        alt=""
                                        style={{
                                          fontSize: "20px",
                                          display: "flex",
                                          justifyContent: "center",

                                          alignItems: "center",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/Hide.png"
                                        alt=""
                                        style={{
                                          fontSize: "20px",
                                          display: "flex",
                                          justifyContent: "center",

                                          alignItems: "center",
                                        }}
                                      />
                                    )}
                                  </Box>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormHelperText error>
                          {touched.password && errors.password}
                        </FormHelperText>
                        {/* {touched.password && (
                      <StrengthBoxes
                        password={values.password}
                        classes={classes}
                      />
                    )} */}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          onPaste={(e) => e.preventDefault()}
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          onChange={handleChange}
                          value={values.confirmpassword}
                          name="confirmpassword"
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setshowConfirmPassword(!showConfirmPassword)
                                  }
                                  edge="end"
                                >
                                  <Box className={classes.passsec}>
                                    {showConfirmPassword ? (
                                      <img
                                        src="/images/Eye.png"
                                        alt=""
                                        style={{
                                          fontSize: "20px",
                                          display: "flex",
                                          justifyContent: "center",

                                          alignItems: "center",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src="/images/Hide.png"
                                        alt=""
                                        style={{
                                          fontSize: "20px",
                                          display: "flex",
                                          justifyContent: "center",

                                          alignItems: "center",
                                        }}
                                      />
                                    )}
                                  </Box>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormHelperText error>
                          {touched.confirmpassword && errors.confirmpassword}
                        </FormHelperText>
                      </Grid>
                      {/* <Grid item xs={12} sm={12}>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        textAlign: "start",
                      }}
                    >
                      <Checkbox
                        type="checkbox"
                        name="check"
                        onChange={handleChange}
                        value={values.check}
                        style={{ width: "20px", height: "20px" }}
                      />
                      <Typography
                        variant="body2"
                        style={{ color: "#FFFFFF99", fontSize: "11px" }}
                      >
                        I accept the{" "}
                        <span
                          className={classes.newbox}
                          onClick={() =>
                            window.open("/terms-condition", "_blank")
                          }
                        >
                          Terms and Conditions,{" "}
                        </span>
                        <span
                          className={classes.newbox}
                          onClick={() =>
                            window.open("/privacy-policy", "_blank")
                          }
                        >
                          Privacy Policy{" "}
                        </span>
                        
                        of BET BY HEART.
                      </Typography>
                    </div>
                    <FormHelperText error>
                      {touched.check && errors.check}
                    </FormHelperText>
                  </Grid> */}
                      <Grid item xs={12} sm={12}>
                        {" "}
                        <Button
                          type="submit"
                          disabled={isloading}
                          variant="contained"
                          color="primary"
                          id="create-account-btn"
                          fullWidth
                        >
                          CREATE ACCOUNT&nbsp;&nbsp;
                          {isloading && <ButtonLoader />}
                        </Button>
                      </Grid>
                    </Grid>

                    <Box mt={2} className="displayCenter w-full">
                      <Typography variant="body2" color="primary">
                        Already have an account?
                        <Button
                          style={{ color: "#FFA500" }}
                          variant="text"
                          className="ghost"
                          id="signUp"
                          // onClick={() => setIsSignUpActive(false)}
                          onClick={() => {
                            setIsSignUpActive(false);
                            setForgotPass(false);
                          }}
                        >
                          LOGIN
                        </Button>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    variant="body2"
                    style={{ color: "#FFFFFF99", fontSize: "14px", textAlign: 'center' }}
                  >
                    I accept the{" "}
                    <span
                      className={classes.newbox}
                      onClick={() => setshowTAndConditionPopup({
                        show: true,
                        type: 'term-condition'
                      })}
                    >
                      Terms and Conditions,{" "}
                    </span>
                    <span
                      className={classes.newbox}
                      onClick={() => setshowTAndConditionPopup({
                        show: true,
                        type: 'privacy-policy'
                      })}
                    >
                      Privacy Policy{" "}
                    </span>

                    of BET BY HEART.
                  </Typography>
                </Form>
              )}
            </Formik>
          )}
          {otpVerifyForSignUp && (
            <Formik
              onSubmit={(values) => handleSignUpOtp(values)}
              initialValues={{
                otp: "",
              }}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={yep.object().shape({
                otp: yep
                  .string()
                  .required("OTP is required.")
                  .matches(/^[0-9]*$/, "Must be a valid OTP.")
                  .max(6, "Should not exceeds 6 digits.")
                  .min(6, "Must be only 6 digits."),
              })}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form style={{}}>
                  <Typography variant="h6" style={{ fontWeight: "600" }}>
                    OTP Verification
                  </Typography>
                  <Typography variant="body2" srtle={{ marginTop: "5px" }}>
                    An OTP has been sent to your entered email address&nbsp;
                    {window.localStorage.getItem("email")} Please check your mail
                    and enter the code to verify your account.
                  </Typography>
                  <div className={classes.optFieldBox}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="number"
                      onWheel={() => document.activeElement.blur()}
                      placeholder="Enter OTP"
                      onChange={handleChange}
                      value={values.otp}
                      name="otp"
                      onBlur={handleBlur}
                    />
                    <FormHelperText error>
                      {touched.otp && errors.otp}
                    </FormHelperText>
                    <Box style={{ display: "flex", justifyContent: "end" }}>
                      {auth.timeLeft?.minutes > 0 ||
                        auth.timeLeft?.seconds > 0 ? (
                        <>
                          <Box>
                            <Typography
                              variant="body1"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                paddingTop: "10px",
                                fontWeight: "800",
                              }}
                            >
                              {minute.length > 1 ? minute : "0" + minute} :{" "}
                              {second.length > 1 ? second : "0" + second}
                            </Typography>{" "}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{
                              color: "rgb(255, 176, 0)",

                              fontWeight: "800",
                              textTransform: "none",
                            }}
                            // fullWidth
                            onClick={() => {
                              resetotphandle();
                            }}
                            disabled={
                              (auth.timeLeft && auth.timeLeft.seconds > 0) ||
                              isloading
                            }
                          >
                            {" "}
                            {loader ? (
                              <ButtonLoader color={"rgb(255, 176, 0)"} />
                            ) : (
                              "Resend OTP"
                            )}
                          </Button>{" "}
                          &nbsp;
                        </>
                      )}{" "}
                    </Box>
                  </div>
                  <Button
                    type="submit"
                    disabled={isloading}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit&nbsp;&nbsp;{isloading && <ButtonLoader />}
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Box>
        <Box className="form-container sign-in-container">
          {!forgotPass && (
            <>
              <Formik
                onSubmit={(values) => handleSignIn(values)}
                initialValues={{
                  email: "",
                  password: "",
                  confirmpassword: "",
                }}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={yep.object().shape({
                  email: yep
                    .string()
                    .max(60, "Should not exceeds 60 characters.")
                    .email("Please enter a valid email address.")
                    .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
                    .required("Email is required."),
                  password: yep
                    .string()
                    .trim()
                    .required("Please enter password.")
                    .min(4, "Please enter at least 4 characters."),
                })}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form className="justifyBetween">
                    <Box className="textFieldSection">
                      <Box mb={3} className="displaySpacebetween">
                        <Typography variant="h6" style={{ fontWeight: "600" }}>
                          LOGIN
                        </Typography>
                      </Box>

                      <TextField
                        variant="outlined"
                        fullWidth
                        type="email"
                        placeholder="Email address"
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                      />
                      <FormHelperText error>
                        {touched.email && errors.email}
                      </FormHelperText>

                      <TextField
                        style={{ marginTop: "10px" }}
                        variant="outlined"
                        fullWidth
                        type={showPasswordLog ? "text" : "password"}
                        placeholder="Password"
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowPasswordLog(!showPasswordLog)
                                }
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showPasswordLog ? (
                                    <img
                                      src="/images/Eye.png"
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",

                                        alignItems: "center",
                                      }}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="/images/Hide.png"
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",

                                        alignItems: "center",
                                      }}
                                      alt=""
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error>
                        {touched.password && errors.password}
                      </FormHelperText>

                      <Box mb={3} mt={2} align="right">
                        {/* <Box className="displayStart">
                        <Checkbox
                          type="checkbox"
                          name="check"
                          onChange={handleChange}
                          value={values.check}
                          style={{ width: "20px", height: "20px" }}
                        />
                        &nbsp;&nbsp;
                        <Typography
                          variant="body2"
                          style={{ color: "#FFFFFF99", fontSize: "12px" }}
                        >
                          Remember me{" "}
                        </Typography>
                      </Box> */}

                        <a
                          style={{
                            color: "#FFFFFF99",
                            fontSize: "12px",
                            cursor: "pointer",
                            fontWeight: "300",
                          }}
                          onClick={() => {
                            setForgotPass(true);
                          }}
                        >
                          Forgot password?
                        </a>
                      </Box>
                      <Button
                        disabled={isloading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        LOGIN&nbsp;&nbsp;{isloading && <ButtonLoader />}
                      </Button>
                      <Box my={2} textAlign="center" className="orDivider">
                        <Typography variant="body2">or</Typography>
                      </Box>
                      <Box
                        mt={2}
                        className="googleButton"
                        textAlign="center"
                        align="center"
                      >
                        <Box style={{ width: "209px" }}>
                          <GoogleOAuthProvider
                            customStyle="custom-google-login-button"
                            style={{ borderRadius: "10px" }}
                            clientId="33822398503-oov0i07vr8mrv3jfsisudjsrul52oal8.apps.googleusercontent.com"
                          >
                            {googleResonseLoading ? <CircularProgress color="inherit" /> : <SocialLoginButton responseGoogle={responseGoogle} />}
                          </GoogleOAuthProvider>
                        </Box>

                        {/* <Box
                        mt={1}
                        mb={2}
                        style={{ width: "209px" }}
                        align="center"
                      >
                        <FacebookLogin
                          appId={clientId}
                          textButton="Continue With Facebook"
                          fields="name,email,picture"
                          callback={(res) => responseGoogle(res, "facebook")}
                          size="small"
                          icon={
                            <FaFacebookF
                              style={{
                                marginRight: "5px",
                                fontSize: "18px",
                                color: "#1768E2",
                              }}
                            />
                          }
                          buttonStyle={{
                            background: "rgb(255, 255, 255)",
                            color: "#3c4043",
                            borderRadius: "5px",
                            padding: "12px 22px",
                            fontSize: "13px",
                            fontWeight: "500",
                            display: "flex",
                            border: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "capitalize",
                            whiteSpace: "pre",
                          }}
                        />
                      </Box> */}
                      </Box>
                    </Box>
                    <Box className="text-center">
                      <Typography variant="body2" color="primary">
                        Don't have an account?
                        <Button
                          style={{ color: "#FFA500" }}
                          variant="text"
                          className="ghost"
                          id="signUp"
                          onClick={() => setIsSignUpActive(true)}
                        >
                          SIGN UP
                        </Button>
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ color: "#FFFFFF99", fontSize: "14px" }}
                      >
                        I accept the{" "}
                        <span
                          className={classes.newbox}
                          onClick={() => setshowTAndConditionPopup({
                            show: true,
                            type: 'term-condition'
                          })}
                        >
                          Terms and Conditions,{" "}
                        </span>
                        <span
                          className={classes.newbox}
                          onClick={() => setshowTAndConditionPopup({
                            show: true,
                            type: 'privacy-policy'
                          })}
                        >
                          Privacy Policy{" "}
                        </span>

                        of BET BY HEART.
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {forgotPass && !otpVerify && !resetPass && (
            <Formik
              onSubmit={(values) => handleForget(values)}
              initialValues={{
                email: "",
              }}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={yep.object().shape({
                email: yep
                  .string()
                  .max(60, "Should not exceeds 60 characters.")
                  .email("Please enter a valid email address.")
                  .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
                  .required("Email is required."),
              })}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form className="justifyBetween">
                  <Box className="textFieldSection">
                    <Box mb={3}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "600", textTransform: "uppercase" }}
                      >
                        FORGET Password?
                      </Typography>
                      <Typography variant="body2" style={{ marginTop: "5px" }}>
                        Forgot your password? No worries! Enter your email address
                        below and we'll send you instructions on how to reset it.
                      </Typography>
                    </Box>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                    />
                    <FormHelperText error>
                      {touched.email && errors.email}
                    </FormHelperText>

                    <Button
                      style={{ marginTop: "16px" }}
                      disabled={isloading}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      SUBMIT&nbsp;&nbsp;{isloading && <ButtonLoader />}
                    </Button>
                  </Box>
                  {/* <a
          style={{ color: "#ffb000" }}
          onClick={() => {
            setOtpVerify(false);
            setForgotPass(false);
          }}
        >
          Sign in?
        </a> */}

                  <Typography variant="body2" color="primary">
                    Already have an account?
                    <Button
                      style={{ color: "#FFA500" }}
                      variant="text"
                      className="ghost"
                      id="signUp"
                      // onClick={() => setIsSignUpActive(false)}
                      onClick={() => {
                        setIsSignUpActive(false);
                        setForgotPass(false);
                      }}
                    >
                      LOGIN
                    </Button>
                  </Typography>
                </Form>
              )}
            </Formik>
          )}
          {forgotPass && otpVerify && !resetPass && (
            <Formik
              onSubmit={(values) => handleFormSubmitOTP(values)}
              initialValues={{
                otp: "",
              }}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={yep.object().shape({
                otp: yep
                  .string()
                  .required("OTP is required.")
                  .matches(/^[0-9]*$/, "Must be a valid OTP.")
                  .max(6, "Should not exceeds 6 digits.")
                  .min(6, "Must be only 6 digits."),
              })}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "600", marginBottom: "5px" }}
                  >
                    OTP Verification
                  </Typography>
                  <Typography variant="body2" srtle={{ marginTop: "5px" }}>
                    An OTP has been sent to your entered email address&nbsp;
                    {window.localStorage.getItem("email")} Please check your mail
                    and enter the code to verify your account.
                  </Typography>
                  <div className={classes.optFieldBox}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="number"
                      placeholder="OTP"
                      onWheel={() => document.activeElement.blur()}
                      onChange={handleChange}
                      value={values.otp}
                      name="otp"
                      onBlur={handleBlur}
                    />

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <FormHelperText error>
                        {touched.otp && errors.otp}
                      </FormHelperText>
                      {auth.timeLeft?.minutes > 0 ||
                        auth.timeLeft?.seconds > 0 ? (
                        <>
                          <Box>
                            <Typography
                              variant="body1"
                              style={{
                                color: "#FFFFFF99",
                                fontSize: "12px",

                                fontWeight: "800",
                              }}
                            >
                              {minute.length > 1 ? minute : "0" + minute} :{" "}
                              {second.length > 1 ? second : "0" + second}
                            </Typography>{" "}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{
                              color: "rgb(255, 176, 0)",
                              fontWeight: "800",
                              textTransform: "none",
                              position: "absolute",
                              right: "25px",
                            }}
                            // fullWidth
                            onClick={() => {
                              resetotphandle();
                            }}
                            disabled={
                              (auth.timeLeft && auth.timeLeft.seconds > 0) ||
                              isloading
                            }
                          >
                            {" "}
                            {loader ? (
                              <ButtonLoader color={"rgb(255, 176, 0)"} />
                            ) : (
                              "Resend OTP"
                            )}
                          </Button>{" "}
                          &nbsp;
                        </>
                      )}{" "}
                    </Box>
                  </div>
                  {/* <a
          style={{ color: "#ffb000" }}
          onClick={() => {
            setForgotPass(false);
          }}
        >
          Sign in?
        </a> */}
                  <Button
                    disabled={isloading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit&nbsp;&nbsp;{isloading && <ButtonLoader />}
                  </Button>
                </Form>
              )}
            </Formik>
          )}
          {forgotPass && otpVerify && resetPass && (
            <Formik
              onSubmit={(values) => handleFormresetPassword(values)}
              initialValues={{
                password: "",
                confirmpassword: "",
              }}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={yep.object().shape({
                password: yep
                  .string()
                  .trim()
                  .required("Please enter password.")
                  .min(4, "Please enter at least 4 characters."),
                confirmpassword: yep
                  .string()
                  .required("Please enter confirm password.")
                  .oneOf([yep.ref("password"), null], "Password must match."),
              })}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form className="justifyBetween">
                  <Box className="textFieldSection">
                    <Box mb={3}>
                      <Typography variant="h6" style={{ fontWeight: "600" }}>
                        Reset Password
                      </Typography>
                      <Typography variant="body2" style={{ marginTop: "5px" }}>
                        Enter your new password below to reset your account
                        password.
                      </Typography>
                    </Box>

                    <TextField
                      variant="outlined"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      placeholder="New password"
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Box className={classes.passsec}>
                                {showPassword ? (
                                  <img
                                    src="/images/Eye.png"
                                    alt=""
                                    style={{
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      marginRight: "20px",
                                      alignItems: "center",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="/images/Hide.png"
                                    alt=""
                                    style={{
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      marginRight: "20px",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.password && errors.password}
                    </FormHelperText>

                    <TextField
                      style={{ margin: "10px 0px 0px" }}
                      variant="outlined"
                      fullWidth
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      onChange={handleChange}
                      value={values.confirmpassword}
                      name="confirmpassword"
                      onBlur={handleBlur}
                      onPaste={(e) => e.preventDefault()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setshowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              <Box className={classes.passsec}>
                                {showConfirmPassword ? (
                                  <img
                                    src="/images/Eye.png"
                                    alt=""
                                    style={{
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      marginRight: "20px",
                                      alignItems: "center",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="/images/Hide.png"
                                    alt=""
                                    style={{
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      marginRight: "20px",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText error>
                      {touched.confirmpassword && errors.confirmpassword}
                    </FormHelperText>
                    <Box mt={3}>
                      <Button
                        disabled={isloading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        RESET PASSWORD&nbsp;&nbsp;{isloading && <ButtonLoader />}
                      </Button>
                    </Box>
                  </Box>

                  <Typography variant="body2" color="primary">
                    Already have an account?
                    <Button
                      style={{ color: "#FFA500" }}
                      variant="text"
                      className="ghost"
                      id="signUp"
                      // onClick={() => setIsSignUpActive(false)}
                      onClick={() => {
                        setIsSignUpActive(false);
                        setForgotPass(false);
                      }}
                    >
                      LOGIN
                    </Button>
                  </Typography>
                </Form>
              )}
            </Formik>
          )}
        </Box>
        <Box className="overlay-container">
          <Box className="overlay">
            <Box
              className={`overlay-panel overlay-left ${isSignUpActive ? "overlay-move-left" : ""
                }`}
            >
              <Box p={2}>
                <img
                  src="images/logo.png"
                  className="horsetopBox"
                  alt=""
                  style={{ maxWidth: "150px", margin: "auto" }}
                />

                <Box className="jointTextBox">
                  {/* <Typography variant="h4">Instant Signup</Typography> */}
                  {/* <img src="/images/get100.svg" /> */}
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{ color: "#FFFFFF99", marginTop: "11px" }}
                  >
                    Join our vibrant gaming community.
                  </Typography>
                </Box>
                <img
                  src="images/horse_men.png"
                  alt=""
                  className="horseBox"
                  style={{ maxWidth: "350px" }}
                />
                {/* <Button
                className="ghost"
                id="signIn"
                onClick={() => setIsSignUpActive(false)}
              >
                Sign In
              </Button> */}
              </Box>
            </Box>

            <Box
              p={2}
              className={`overlay-panel overlay-right ${isSignUpActive ? "overlay-move-right" : ""
                }`}
            >
              <img
                src="images/logo.png"
                alt=""
                className="logotoside"
                style={{ maxWidth: "135px" }}
              />
              <img
                src="images/login.png"
                alt=""
                style={{ maxWidth: "245px" }}
                className="logobottomside"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}


const SocialLoginButton = ({ responseGoogle }) => {

  const signIn = useGoogleLogin({
    onSuccess: async (response) => {
      // You will receive a token here
      const token = response.access_token;
      responseGoogle(token)
    },
    onError: (error) => {
      console.error('Google login failed:', error);
    },
  });
  return (
    <button type="button" id="signup-btn" className="google-sign-btn flex gap-x-1 active:bg-zinc-300" onClick={signIn}>
      <img src="/images/google.png" alt="google-signin-btn" className="scale-90" />
      <span className="text-zinc-700 text-sm">Sign in with Google</span>
    </button>
  )
}

const StrengthBoxes = ({ password, classes }) => {
  const getPasswordStrengthColor = () => {
    if (
      password.length >= 12 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]/.test(password)
    ) {
      return { color: "#3BC117", text: "Secure" };
    } else if (
      password.length >= 8 &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\d/.test(password)
    ) {
      return { color: "#3BC117", text: "Average" };
    } else {
      return { color: "#FF372B", text: "High risk" };
    }
  };

  const strength = getPasswordStrengthColor();

  return (
    <Box className={classes.errorPassClx}>
      <Box
        style={{
          background:
            strength.text === "Secure"
              ? "#3BC117"
              : strength.text === "Average"
                ? "#FBBC36"
                : strength.text === "High risk"
                  ? "#FF372B"
                  : "#3F467D",
          height: "4px",
          width: "100%",
          borderRadius: "5px",
        }}
      ></Box>
      <Box
        style={{
          background:
            strength.text === "Secure"
              ? "#3BC117"
              : strength.text === "Average"
                ? "#FBBC36"
                : "#3F467D",
          height: "4px",
          width: "100%",
          borderRadius: "5px",
        }}
      ></Box>
      <Box
        style={{
          background: strength.text === "Secure" ? "#3BC117" : "#3F467D",
          height: "4px",
          width: "100%",
          borderRadius: "5px",
        }}
      ></Box>
      <FormHelperText
        style={{
          color:
            strength.text === "Secure"
              ? "#3BC117"
              : strength.text === "Average"
                ? "#FBBC36"
                : "#FF372B",
          fontSize: "12px",

          whiteSpace: "nowrap",
        }}
      >
        {strength.text}
      </FormHelperText>
    </Box>
  );
};
