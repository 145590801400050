import { createSlice } from "@reduxjs/toolkit";
import { getSessionStorageItem, setSessionStorageItem } from "../../utils/sessionStorageUtils";
import { generatedFunction } from "../../hooks/useRNG";

const initialState = {
  diceVal : generatedFunction()?.result,
  gameMode: null,
  gameType : null,
  gameObj: {
    token: 'green',
    inGamePlayer: 2,
    feeType: "money",
    feeAmmount: 1,
    selectedBoard: "default",
    inGameSelectedColor: [],
  },
  rooms: [],
  roomProperties : null,
  currentRoomProperties: null,
  userData: null,
  singleLobbyData: null,
  currentRoomPlayers: [],
  rematchPlayers: [],
  winners : [],
  isConnected : false,
  isCreated : false,
  bots : [],
  playerLeft : null,
  connectedPlayers : null,
  inGameSelectedColor: [],
  oppponentColor : null
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    reset : (state) => {
      state.currentRoomProperties = null;
      state.currentRoomPlayers = null;
      state.userData = null;
      state.singleLobbyData = null;
      state.roomProperties = null;
      state.playerLeft = null;
    },
    setDiceVal : (state,action) => {
       state.diceVal = action.payload;
    },
    setGameMode: (state, action) => {
      state.gameMode = action.payload;
    },
    setGameType: (state, action) => {
      // // console.log("action", action.payload);
      state.gameType = action.payload;
      setSessionStorageItem("gameType", action.payload)
    },
    setSingleLobbyData: (state, action) => {
      // // console.log("action", action.payload);
      state.singleLobbyData = action.payload;
    },
    setPlayerLeft : (state,action) => {
      state.playerLeft = action.payload;
    },
    setUserData: (state, action) => {
      const payload = action.payload;
      if(payload ===  null) {
          state.userData = null;
          return;
      }
      
      state.userData = {
        ...state.userData,
        ...payload,
      };
    },
    setGameObj: (state, action) => {
      const payload = action.payload;
      if(payload === null) {
        state.gameObj = {
          ...state.gameObj,
          selectedBoard : 'default'
        };
      } else {
        state.gameObj = {
          ...state.gameObj,
          ...payload,
        };
      }
    },
    setCurrentRoomProperties: (state, action) => {
      const payload = action.payload;
      if(payload === null) {
        state.currentRoomProperties = null;
        return;
      }
      
      state.currentRoomProperties = {
        ...state.currentRoomProperties,
        ...payload,
      };
    },
    setProperties: (state, action) => {
      const {properties} = action.payload;
      state.roomProperties = properties;
      
    },
    setCurrentRoomPlayers: (state, action) => {
      if(action.payload === null) {
        state.currentRoomPlayers = null;
        return;
      }
      state.currentRoomPlayers = action?.payload?.tokenArr;
      if(state.currentRoomPlayers ){
        state.connectedPlayers = state?.currentRoomPlayers.filter(player => player?.isConnected).length;
      }
    },
    setInGameSelectedColor: (state, action) => {
      state.inGameSelectedColor = action.payload;
      let botSessionData = getSessionStorageItem("botGameSession");
      setSessionStorageItem('botGameSession' , {...botSessionData, inGameSelectedColor : action.payload, gameObj : state.gameObj});
      if(state?.gameObj?.inGamePlayer === 2 && state.inGameSelectedColor) {
        const opponent = state.inGameSelectedColor.find(color => color !== state?.gameObj?.token);
        state.oppponentColor = opponent;
      }
    },
    setrRmatchPlayers: (state, action) => {
      if(action.payload === null) {
         state.rematchPlayers = [];
         return;
      }
      
      const {accept, uid} = action.payload;
      // const rematchPlayers = state.rematchPlayers;
      // if(!rematchPlayers?.find(player => player?.uid === action.payload)) {
        const getPlayer = state.currentRoomPlayers?.find(player => player?.uid === uid);
        getPlayer.isAccept = accept;
        state.rematchPlayers.push(getPlayer);
     // }
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setisConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    setWinners : (state,action) => {
      state.winners = action?.winners?.length > 0 ? [...action.winners , action.payload] : action.payload;
    },
    setIsCreated : (state,action) => {
        state.isCreated = action.payload;
    },
    setBots : (state,action) => {
      let botSessionData = getSessionStorageItem("botGameSession");
      setSessionStorageItem('botGameSession' , {...botSessionData, bots : action.payload});
      state.bots = action.payload;
    }
  },
});

export default gameSlice.reducer;
export const {
  setGameMode,
  setGameObj,
  setInGameSelectedColor,
  setRooms,
  setSingleLobbyData,
  setUserData,
  setCurrentRoomProperties,
  setCurrentRoomPlayers,
  setisConnected,
  setWinners,
  setGameType,
  setIsCreated,
  setBots,
  setPlayerLeft,
  reset,
  setProperties,
  setrRmatchPlayers,
  setDiceVal
} = gameSlice.actions;
