import React, { Suspense, Fragment, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import PageLoading from "src/component/PageLoading";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import AuthContext from "src/context/Auth";
import { Toaster } from "react-hot-toast";
import AuthGuard from "src/component/AuthGuard";
import LogoutTimer from "./component/SessionOutComponent";
import useScript from "./views/pages/BlackJack/GamePlay/useScript";
import { GameSound } from "./views/pages/Ludo/utils/sound";
import { Helmet } from "react-helmet";
import { useLocation } from "react-use";
import Maintain from "./views/pages/Maintainence/Maintain";



export const gameSound = new GameSound();

const history = createBrowserHistory();
const isMaintain = false
function App() {
  const theme = createTheme();
  useScript("BlackJack/js/lib/jquery-3.5.1.min.js");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [metaTitle, setMetaTitle] = useState("")
  const [metaDescription, setMetaDescription] = useState("")
  const [currentUrl, setCurrentUrl] = useState("")
  const [metaKeywords, setMetaKeywords] = useState("")
  const location = useLocation();


  useEffect(() => {
    const currentHref = `${window.location.origin}${location.pathname}`;
    console.log(currentHref);

    // Update meta tags based on the current route
    // document.title = "BetByHeart";

    switch (location.pathname) {
      case "/":
        setMetaTitle("Play Game Online & Earn Money | BetByHeart - Real Cash Rewards");
        setMetaDescription("Play game online & earn money with BetByHeart. Enjoy exciting games, real cash rewards, and a safe platform for players. Start winning today and boost your earnings!");
        setMetaKeywords("play, game, online, earn, money");
        break;
      case "/blackjack":
        setMetaTitle("Play Blackjack Game Online for Real Money - BetByHeart");
        setMetaDescription("Play Blackjack Game Online for Real Money at BetByHeart. Enjoy thrilling games, win real cash rewards, and experience the excitement of online blackjack anytime, anywhere!");
        setMetaKeywords("blackjack, game, online, real, money");
        break;
      case "/lottery":
        setMetaTitle("Play Lottery Ticket Game Online for Real Cash | Bet By Heart");
        setMetaDescription("Play lottery ticket game online for real cash at Bet By Heart. Win big cash prizes and enjoy exciting lottery games with a chance to earn real money. Start playing now!");
        setMetaKeywords("lottery, ticket, game, play, online, for, real, Cashy");
        break;
      case "/derby":
        setMetaTitle("Play Horse Derby Game Online & Earn Real Money | BetByHeart");
        setMetaDescription("Play Horse Derby Game Online & Earn Real Money with BetByHeart. Enjoy exciting gameplay, place bets, and win cash rewards. Sign up now and start earning while playing!");
        setMetaKeywords("horse, derby, game, online, earn, real, money");
        break;
      case "/h2h":
        setMetaTitle("Play H2H Game Online for Earn Real Money - Bet By Heart");
        setMetaDescription("Play H2H Game Online for Real Money at Bet By Heart. Join now to enjoy exciting games and earn cash rewards while having fun! Sign up today!");
        setMetaKeywords("h2h, game, online, for, real, money");
        break;
      case "/features":
        setMetaTitle("Earn Money Playing Games Online | Bet by Heart");
        setMetaDescription("Earn money playing games online at Bet by Heart! Join now for exciting games and real cash rewards. Start your winning journey today!.");
        setMetaKeywords("earn, money, playing, games, online");
        break;
      case "/about":
        setMetaTitle("Top Online Games to Earn: Play and Win at Bet By Heart");
        setMetaDescription("Discover the top online games to earn real cash! Play and win at Bet By Heart. Join now for exciting games and rewarding experiences!");
        setMetaKeywords("online, games, to, earn");
        break;
      default:
        setMetaTitle("BetByHeart");
        setMetaDescription("Join BetByHeart for exciting games and real cash rewards. Play and win today!");
        setMetaKeywords("play games online, earn money, cash rewards, BetByHeart, online gaming, win real money");
    }

    setCurrentUrl(currentHref);
  }, [location.pathname]);







  return (
    <div className="App">
      <Helmet>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://betbyheart.com/images/favicon.png" />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="BetByHeart" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@betbyheart" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://betbyheart.com/images/favicon.png" />

        <meta name="keywords" content={metaKeywords} />
        <meta name="description" content={metaDescription} />
        <title>{metaTitle}</title>

      </Helmet>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <Toaster
              position="bottom-center"
              autoClose={3000}
              reverseOrder={false}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              // theme="light"
              theme={theme.palette.type}
            />
            <LogoutTimer />
            <Router history={history}>
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {
          isMaintain ?
            <Route
              path="*"
              render={() => <Maintain />}
            /> :
            props.data.map((route, i) => {
              const Component = route.component;
              const Guard = route.guard ? AuthGuard : Fragment;
              const Layout = route.layout || Fragment;
              return (
                <Route
                  exact={route.exact}
                  key={i}
                  path={route.path}
                  render={(props) => (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          <RenderRoutes data={route.routes} />
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </Guard>
                  )}
                />
              );
            })
        }
      </Switch>
    </Suspense>
  );
}
