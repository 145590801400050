import { Box, Button, CircularProgress, Dialog, DialogContent, Slide, } from '@material-ui/core';
import React, { useState } from 'react'
import TermsConditions from '../../views/pages/StaticContent/TermsConditions'
import PrivacyPolicy from '../../views/pages/StaticContent/PrivacyPolicy'
import axios from 'axios';
import ApiConfig from 'src/ApiConfig/ApiConfig';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function TermAndConditionMsgModal({open,setshowCheck,handleCreateAccount,setLoader,isSocialLogin,closeBox}) {
  const [loading, setLoading] = useState(false);


  const handleAcceptTermAndCondition = async (e) => {
    setLoading(true);
    await handleCreateAccount();
    setLoading(false);
  }

  const handleDeny = () => {

    if(isSocialLogin) {
      closeBox();
      acceptTermAndCondition();
      return;
    } 

    setLoader(false);
    setshowCheck(false);
  }
  
  async function acceptTermAndCondition() {
     try {
      setLoading(true);
      let res = await axios({
        method: "POST",
        url: ApiConfig.termAndCondition,
        data: {
          email: window.localStorage.getItem("socialEmail"),
        },
      });
      console.log("res.dfd acceptT", res.data);
      setshowCheck(false);
     } catch (error) {
      console.log("res.dfd acceptT error", error.response.data);
     } finally {
       setLoading(false);
     }
  }
 
  return (
    <>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
          //   onClose={() => {
          //     setOpen(false);
          //   }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Box className="check-term-condition flex flex-col gap-y-2 justify-center items-center py-5 p-2">
              <img
                src="images/logo.png"
                className="horsetopBox"
                alt=""
                style={{ maxWidth: "70px", margin: "auto" }}
              />

              <Box className="flex flex-col justify-center items-center w-full gap-x-2">
                <TermsConditions />
                <PrivacyPolicy />
                {isSocialLogin ? (
                  <>
                    {loading ? (
                      <Box className="flex py-3 sticky bottom-0 bg-[#1C1F36] w-full justify-center">
                        <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      <Box className="flex py-3 sticky bottom-0 bg-[#1C1F36] w-full justify-center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleDeny}
                        >
                          Accept and Continue
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    {loading ? (
                      <Box className="flex py-3 sticky bottom-0 bg-[#1C1F36] w-full justify-center">
                         <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      <Box className="flex py-3 sticky bottom-0 bg-[#1C1F36] w-full justify-center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAcceptTermAndCondition}
                        >
                          Accept and Continue
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default TermAndConditionMsgModal
