export default {
  h1: {
    fontWeight: 600,
    fontSize: 40,
    letterSpacing: "1.6px",
    fontFamily: "'Readex Pro', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "25px !important",
      lineHeight: "40px",
    },
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: "45px",
    fontFamily: "'Readex Pro', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "23px !important",
    },
  },
  h3: {
    fontWeight: 400,
    fontSize: 25,
    fontFamily: "'Readex Pro', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "20px !important",
    },
  },
  h4: {
    fontSize: "28px",
    fontWeight: "600",
    lineHeight: "30px",
    fontFamily: "'Readex Pro', sans-serif", 
    "@media(max-width:767px)": {
      fontSize: "18px !important",
    },
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    "@media(max-width:767px)": {
      fontSize: "16px !important",
    },
    "@media(max-width:600px)": {
      fontSize: "12px !important",
    },
  },
  h6: {
    fontWeight: 300,
    fontSize: 18,
    fontFamily: "'Readex Pro', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "14px !important",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Readex Pro', sans-serif",
  },
  body1: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: "'Readex Pro', sans-serif",
    "@media(max-width:980px)": {
      fontSize: "14px !important",
    },
    "@media(max-width:767px)": {
      fontSize: "12px !important",
    },
  },
  subtitle1: {
    fontSize: 12,
    color: "rgba(255, 255, 255, 0.6)",
    fontFamily: "'Readex Pro', sans-serif",
    fontWeight: 300,
  },
  body2: {
    fontSize: 14,
    fontWeight: 300,
    fontFamily: "'Readex Pro', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "12px !important",
    },
  },
};
