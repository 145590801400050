/* eslint-disable no-use-before-define */
import React, { useState, useContext } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box, List, makeStyles, Avatar, Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory } from "react-router-dom";
import HomeIcon from "src/Icons/HomeIcon";
import DisconnectIcon from "src/Icons/DisconnectIcon";
import { AuthContext } from "src/context/Auth";
import ConfirmModal from "src/component/ConfirmModal";
import toast from "react-hot-toast";
import { CiUser } from "react-icons/ci";
import { RiLogoutBoxLine } from "react-icons/ri";

import { handleLoutFunction } from "src/utils";
const sections = [
  {
    title: "My Profile",
    href: "/my-profile",
    icon: CiUser,
  },

  {
    title: "Logout",
    href: "/",
    icon: RiLogoutBoxLine,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item?.title + depth;

  if (item?.items) {
    const open = matchPath(pathname, {
      path: item?.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item?.icon}
        info={item?.info}
        key={key}
        open={Boolean(open)}
        title={item?.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item?.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item?.href}
        icon={item?.icon}
        info={item?.info}
        key={key}
        title={item?.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 317,
    // background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 47,
    height: 47,

    // "@media (max-width: 767px)": {
    //   width: "30px",
    //   height: "30px",
    // },
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  profileButton: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    margin: "8px",
    "& p": {
      fontWeight: "600",
      color: "#fff",
    },
  },
  borderBottom: {
    top: "48px",
    left: "21px",
    position: "absolute",
    width: "87%",
  },
  button: {
    color: "#ffffffe6",
    padding: "9px 8px",
    fontSize: "13px",
    fontWeight: "400",
    borderRadius: "0px",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  iconbutton: {
    color: "#ffffff91",
    padding: "1px",
    background: "transparent",
    marginRight: "10px",
  },
  profileBox: {
    padding: "6px 0px 2px",
    borderRadius: "10px",
    background: "#1C1F36",
    border: "1px solid #f4a91b54",
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [rightBar, setRightBar] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box className={classes.profileBox}>
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          className="walletbox"
        >
          <Box pb={2} px={1} style={{ position: "relative" }}>
            <img
              src="/images/border_bottom.svg"
              className={classes.borderBottom}
            />
            {sections
              ?.filter((value, i) =>
                value?.title === "Logout"
                  ? sessionStorage.getItem("token")
                  : value
              )
              ?.map((section, i) => {
                const Icon = section.icon;
                return (
                  <>
                    <Box style={{ position: "relative" }}>
                      <Button
                        fullWidth
                        className={classes.button}
                        style={{
                          justifyContent: "left",
                          textTransform: "capitalize",
                        }}
                        key={i}
                        onClick={() => {
                          section.title === "Logout"
                            ? (() => {
                                setRightBar(false);
                                setConfirmModal(true);
                              })()
                            : history.push(section.href);
                        }}
                      >
                        {section.title == "Profile" ? (
                          <Avatar
                            src={auth?.userData?.profilePic || "img.png"}
                            className={classes.avatar}
                            alt={auth?.userData?.fullName}
                          />
                        ) : (
                          <Icon
                            className={classes.iconbutton}
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}{" "}
                        &nbsp;&nbsp;
                        {section.title}
                      </Button>
                    </Box>
                  </>
                );
              })}
          </Box>
        </PerfectScrollbar>
      </Box>
    </Box>
  );

  return (
    <>
      <Avatar
        src={auth?.userData?.profilePic || "img.png"}
        className={classes.avatar}
        onClick={() =>
          localStorage.getItem("ADMIN")
            ? (() => {
                localStorage.removeItem("ADMIN");
                history.push("/");
              })()
            : setRightBar(!rightBar)
        }
        alt={auth?.userData?.fullName}
      />
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>

      <ConfirmModal
        handleClose={() => setConfirmModal(false)}
        confirmModal={confirmModal}
        text="Are you sure, you want to logout?"
        handleSubmit={() => {
          toast.success("You have been logout successfully!");
          handleLoutFunction({ auth });
          setConfirmModal(false);
        }}
        isLoading={false}
      />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
