import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { CgClose } from "react-icons/cg";
import LoadingComp from "./LoadingComp";

const useStyles = makeStyles((theme) => ({
  maindialog: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: "10px",
      border: "1px solid #2F3458",
      background: "#1D1F32",
      boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset",
    },
  },
}));

export default function ConfirmModal({
  text,
  handleClose,
  confirmModal,
  handleSubmit,
  isLoading,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={confirmModal}
      onClose={() => !isLoading && handleClose()}
      fullWidth
      maxWidth="xs"
      className={classes.maindialog}
    >
      <IconButton
        style={{ position: "absolute", right: "0px" }}
        disabled={isLoading}
        onClick={() => handleClose()}
      >
        <CgClose style={{ color: "#ffffffc7" }} />
      </IconButton>
      <DialogContent>
        <Box align="center" mb={2}>
          <Box mt={4} mb={1.8} style={{ padding: "0px 15px" }}>
            <Typography variant="h6">{text}</Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginLeft: "10px",height:"44px" }}
            disabled={isLoading}
          >
            Yes {isLoading && <LoadingComp />}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
