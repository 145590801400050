/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box, List, makeStyles, Avatar, Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory } from "react-router-dom";
import HomeIcon from "src/Icons/HomeIcon";
import DisconnectIcon from "src/Icons/DisconnectIcon";

import ConfirmModal from "src/component/ConfirmModal";
const sections = [
  {
    title: "Profile",
    href: "/my-profile",
    icon: HomeIcon,
  },

  {
    title: "Logout",
    href: "/",
    icon: DisconnectIcon,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item?.title + depth;

  if (item?.items) {
    const open = matchPath(pathname, {
      path: item?.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item?.icon}
        info={item?.info}
        key={key}
        open={Boolean(open)}
        title={item?.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item?.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item?.href}
        icon={item?.icon}
        info={item?.info}
        key={key}
        title={item?.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 317,
    // background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 32,
    height: 32,

    // "@media (max-width: 767px)": {
    //   width: "30px",
    //   height: "30px",
    // },
  },
  avatarBig: {
    cursor: "pointer",
    width: 150,
    height: 150,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  profileButton: {
    background: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    margin: "8px",
    "& p": {
      fontWeight: "600",
      color: "#fff",
    },
  },
  button: {
    color: "#ffffffe6",
    padding: "9px 8px",
    fontSize: "13px",
    fontWeight: "400",
    borderBottom: "0.5px solid rgb(255 255 255 / 1%)",
    borderRadius: "0px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  iconbutton: {
    color: "#fff",
    background: "rgba(255, 255, 255, 0.15)",
    borderRadius: "14px",
    padding: "8px",
    fontSize: "20px",
    marginRight: "10px",
  },
  profileBox: {
    padding: "6px 0px 2px",
    borderRadius: "10px",
    background: "#1C1F36",
    border: "1px solid #f4a91b54",
  },
}));

const NavBar = ({ isBlackJack }) => {
  const classes = useStyles();
  const history = useHistory();

  const [rightBar, setRightBar] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const handleSubmit = () => {
    sessionStorage.removeItem("token");
    localStorage.removeItem("fullName");
    localStorage.removeItem("userName");
    localStorage.removeItem("userID");
    localStorage.removeItem("profilePic");
    history.push("/");
  };
  const handleClose = () => {
    setConfirmModal(false);
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box className={classes.profileBox}>
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          className="walletbox"
        >
          <Box pb={2} px={1}>
            {sections
              ?.filter((value, i) =>
                value?.title === "Logout"
                  ? sessionStorage.getItem("token")
                  : value
              )
              ?.map((section, i) => {
                const Icon = section.icon;
                return (
                  <Button
                    fullWidth
                    className={classes.button}
                    // id="leave-table"
                    style={{
                      justifyContent: "left",
                      textTransform: "capitalize",
                    }}
                    key={i}
                    onClick={() => {
                      section.title === "Logout"
                        ? (() => {
                          setRightBar(false);
                          setConfirmModal(true);
                        })()
                        : isBlackJack &&
                          window.location.pathname === "/blackjack"
                        ? window.open(section.href, "_self")
                        : history.push(section.href);
                    }}
                  >
                    {section.title === "Profile" ? (
                      <Avatar
                        src={localStorage.getItem("profilePic") || "img.png"}
                        className={classes.avatar}
                        alt={localStorage.getItem("profilePic")}
                      />
                    ) : (
                      <Icon
                        className={classes.iconbutton}
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}{" "}
                    &nbsp;&nbsp;
                    {section.title}
                  </Button>
                );
              })}
          </Box>
        </PerfectScrollbar>
      </Box>
    </Box>
  );

  return (
    <>
      <Avatar
        src={localStorage.getItem("profilePic") || "img.png"}
        className={classes.avatar}
        onClick={() =>
          localStorage.getItem("ADMIN")
            ? (() => {
              localStorage.removeItem("ADMIN");
              history.push("/");
            })()
            : setRightBar(!rightBar)
        }
        alt={localStorage.getItem("profilePic")}
      />
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>

      {confirmModal && (
        <ConfirmModal
          text="Are you sure want to logout?"
          handleClose={handleClose}
          confirmModal={confirmModal}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
