import React, { useCallback, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogContent, Slide, } from '@material-ui/core';
import TermsConditions from '../../views/pages/StaticContent/TermsConditions'
import PrivacyPolicy from '../../views/pages/StaticContent/PrivacyPolicy'
import axios from 'axios';
import ApiConfig from 'src/ApiConfig/ApiConfig';
import { RxCross2 } from 'react-icons/rx';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function TermAndConditionAndPrivacyPolicyStaticModal({open,setHide,type}) {

  const handleDeny= () => {
    setHide(false);
  }

  const renderStaticContent = useCallback(() => {
    return type === 'term-condition' ? <TermsConditions/> : <PrivacyPolicy />
  },[type])

  return (
    <>
       {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className="noPadding"
            onClose={() => {
              setHide(false);
            }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Box className="check-term-condition flex flex-col gap-y-2 justify-center items-center py-5 p-2">
            <RxCross2
              className="closeIcon"
              onClick={() => {
                setHide(false);
              }}
            />
              <img
                src="images/logo.png"
                className="horsetopBox"
                alt=""
                style={{ maxWidth: "70px", margin: "auto" }}
              />

              <Box className="flex flex-col justify-center items-center w-full gap-x-2">
                     {renderStaticContent()}
                    
                     <Button
                        variant="contained"
                        color="primary"
                        style={{
                          background: "transparent",
                          borderColor: "orange",
                        }}
                        onClick={handleDeny}
                      >
                      Close
                  </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
     )}
    </>
  )
}

export default TermAndConditionAndPrivacyPolicyStaticModal
