import React, { useState, useEffect, useContext, lazy } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Box,
  Dialog,
  DialogContent,
  Slide,
} from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useLocation } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import { getPotAmountApi } from "../../service/spinApi";

const SpinModal = lazy(() => import("../HomeLayout/SpinModal.jsx"));
const SpinResultModal = lazy(() => import("../HomeLayout/SpinResultModal.jsx"));
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#141627",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // paddingTop: 56,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 230,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // background: "rgba(2, 1, 3, 0.85)",
    background: "#141627",
    color: "#fff",
    minHeight: "calc(100vh - 62px)",
    padding: "60px 0px 0px",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
    padding: "50px 20px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 16px 10px ",
    },
    "& .relativeClx": {
      position: "relative",
      zIndex: "11",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [spinModalOpen, setSpinModalOpen] = useState(false);
  const [spinResultModalOpen, setSpinResultModalOpen] = useState(false);
  const [finalValue, setFinalValue] = useState("Good Luck!");
  const [potAmount, setPotAmount] = useState(null);
  const [bonusSpin, setBonusSpin] = useState(false);

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [window.location.href])



  const loadZendeskScript = () => {
    if (document.getElementById('ze-snippet')) return;
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=f3c3ec83-a4d4-4f80-906e-9e3b568f54c0';
    script.async = true;
    script.onload = () => {
      window.zE('messenger', 'close');
      // window.zE('messenger', 'show');
    };
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadZendeskScript()
  }, [])



  useEffect(() => {
    auth.getUserWallletApi();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname === "/" && localStorage.getItem("ADMIN")) {
      history.push("/");
      localStorage.removeItem("ADMIN");
    }
    if (
      auth.ownerAddress !== auth.account &&
      localStorage.removeItem("ADMIN")
    ) {
      history.push("/");
      localStorage.removeItem("ADMIN");
    }
    getPotAmountApi().then((res) => {
      const potAmount = res?.data?.result?.potAmount;
      console.log("potAmount>>", potAmount);
      setPotAmount(potAmount || 0);
    });
  }, [window.location.pathname, auth.account]);


  useEffect(() => {
    auth.getUserWallletApi();
  }, []);

  const openSpinGame = () => {
    setSpinModalOpen(true);
    setBonusSpin(true);
  };


  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {/* <img
              src="images/backgroundlayout.png"
              alt="image"
              className="layoubackgroundImg"
            /> */}
            <Box className="relativeClx">{children}</Box>
          </div>
        </div>
        <Footer />
        {auth?.userLoggedIn && (
          <>
            <div
              className="fixed bottom-[30%] right-0 z-[999] cursor-pointer  lg:w-14 lg:h-14 rouned-full"
              style={{ filter: "drop-shadow(0px 2px 22px orange)" }}
              onClick={openSpinGame}
            >
              <img
                src="/images/games/spinIcon.png"
                alt="spin-icon"
                className="w-full animate-spin"
              />
            </div>
          </>
        )}

        {spinModalOpen && (
          <Dialog
            open={spinModalOpen}
            TransitionComponent={Transition}
            keepMounted
            className="noPadding"
            // onClose={() => {
            //   setSpinModalOpen(false);
            // }}
            maxWidth="lg"
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <SpinModal
                setSpinResultModalOpen={setSpinResultModalOpen}
                setSpinModalOpen={setSpinModalOpen}
                setFinalValue={setFinalValue}
                potAmount={potAmount}
                auth={auth}
                bonusSpin={bonusSpin}
              />
            </DialogContent>
          </Dialog>
        )}
        {spinResultModalOpen && (
          <Dialog
            open={spinResultModalOpen}
            TransitionComponent={Transition}
            keepMounted
            className="noPadding"
            // onClose={() => {
            //   setSpinResultModalOpen(false);
            // }}
            maxWidth="lg"
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <SpinResultModal
                setSpinResultModalOpen={setSpinResultModalOpen}
                finalValue={finalValue}
                setSpinModalOpen={setSpinModalOpen}
              />
            </DialogContent>
          </Dialog>
        )}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
